<template>
  <div class="content">
    <div class="m-card3 my-info">
      <div class="mc-top">
        <div class="mc-top-title"><span class="mc-top-txt">Profile</span></div>
      </div>
      <div style="margin-top: 40px; width: 900px">
        <Form ref="formInline" :model="formInline" :rules="ruleValidate" :label-width="200">
          <FormItem label="Photo：">
            <div class="my-upload">
              <div class="my-upload-list" v-for="(item, index) in uploadList" :key="'upload' + index" style="width: 100px; height: 100px">
                <template v-if="item.status === 'finished'">
                  <img :src="item.url" style="width: 100px; height: 100px" />
                  <div class="my-upload-list-cover">
                    <Icon type="ios-eye-outline" @click.native="handleView(item.url)"></Icon>
                    <Icon type="ios-trash-outline" @click.native.stop.prevent="handleRemove(item)"></Icon>
                  </div>
                </template>
                <template v-else>
                  <Spin v-if="item.showProgress" style="width: 100px; height: 100px">
                    <Icon type="ios-loading" size="20" class="demo-spin-icon-load"></Icon>
                  </Spin>
                </template>
              </div>
              <Upload ref="upload" :show-upload-list="false" :default-file-list="defaultList" :on-success="handleSuccess" :format="['jpg', 'jpeg', 'png']" :max-size="2048" :headers="formHeaders" :on-format-error="handleFormatError" :on-exceeded-size="handleMaxSize" :before-upload="handleBeforeUpload" type="drag" :action="uploadUrl" style="display: inline-block" v-show="uploadList && uploadList.length < 1">
                <div style="width: 100px;height: 100px;padding-top: 18px;color: rgb(133, 133, 133);">
                  <div style="font-size: 25px">
                    +
                    <p style="font-size: 12px">{{ $t("lang.Upload") }}</p>
                  </div>
                </div>
              </Upload>
              <iModal title="图片查看" cancel-text v-model="visible">
                <div style="text-align: center">
                  <img :src="imgName" v-if="visible" style="max-width: 100%" />
                </div>
              </iModal>
            </div>
            <p class="upload-p" style="display: block; font-size: 12px">
              Requirements: .jpg/png within 2M, suggested size is 200*200px
            </p>
          </FormItem>

          <FormItem label="Name：" prop="nameEn">
            <Input v-model="formInline.nameEn" placeholder="Please enter the name" maxlength="100" style="width: 600px" />
          </FormItem>

          <FormItem label="E-mail：" prop="email">
            <Input v-model="formInline.email" placeholder="Please enter the email" :disabled="formInline.email ? true : false" maxlength="100" style="width: 600px" />
          </FormItem>

          <FormItem label="Company：" prop="company">
            <Input v-model="formInline.company" placeholder="Please enter the company" maxlength="100" style="width: 600px" />
          </FormItem>

          <FormItem label="Job title：" prop="job">
            <Input v-model="formInline.job" placeholder="Please enter the job title" maxlength="100" style="width: 600px" />
          </FormItem>

          <FormItem label="Mobile：" prop="phone">
            <Input v-model="formInline.phone" placeholder="Please enter the mobile" maxlength="100" style="width: 600px; display: inline-block" />
          </FormItem>

          <FormItem label="Region：" prop="region">
            <Select v-model="formInline.region" style="width:600px" @on-change="onConfirm" filterable>
              <Option v-for="item in country" :value="item.nameEn" :key="item.nameEn">{{ item.nameEn }}</Option>
            </Select>
          </FormItem>

          <FormItem label="Interest:" prop="category" v-if="categoryList">
            <Row v-for="(item, index) in formInline.category" :key="cateKey + '' + index" style="margin-bottom: 5px">
              <Col span="10">
              <Cascader v-model="formInline.category[index]" filterable :data="categoryList" :key="'categoryArr' + index" :ref="'categoryArr' + index" @on-change="changeCategoryArr()"></Cascader>
              </Col>
              <Col span="2" style="text-align: center">
              <Icon type="ios-add-circle-outline" v-if="index === 0" @click="addCategoryArrr()" style="color: #2d8cf0; font-size: 20px; cursor: pointer" />
              <Icon type="ios-remove-circle-outline" v-else style="color: red; font-size: 20px; cursor: pointer" @click="delCategoryArrr(index)" />
              </Col>
            </Row>
          </FormItem>
        <div style="padding-bottom: 30px"></div>
          <FormItem>
            <Button type="primary" @click="handleSubmit('formInline')" :loading="loading">Save</Button>
          </FormItem>
        </Form>
        <div style="padding-bottom: 100px"></div>
      </div>
    </div>
  </div>
</template>

<script> 
import { mapGetters, mapActions, mapMutations } from "vuex";
import provincial from "@/assets/js/provinces.json"; //省市区json
export default {
  name: "my-info",
  data () {
    const that = this;
    const logo = (rule, value, callback) => {
      if (that.uploadList && that.uploadList.length) {
        callback();
      } else {
        that.$Message.warning("Please select photo ");
      }
    };
    const country = (rule, value, callback) => {
      if (that.formInline.country && that.formInline.province && that.formInline.city) {
        callback();
      } else {
        that.$Message.warning("Please select ");
      }
    };

    const categoryValid = (rule, value, callback) => {
      if (value && value.length && value[0].length) {
        callback();
      } else {
        callback(new Error("Please select one"));
      }
    };

    return {
      loading: false,
      type: [],
      type2: [],
      categoryList: [],
      cateKey: 0,
      formInline: {
        nameEn: "",
        phone: "", // 手机
        email: "", // 手机
        company: "", // 公司
        job: "",
        region: [],
        content: "",
        province: "",
        city: [],
        category: [],
      },
      areaList: [], //地址数据
      ruleValidate: {
        // logo: [{ validator: logo, trigger: "blur" }],
        email: [{ type: "email", trigger: "blur" }],
        // name: [{ message: "Please enter the name", trigger: "blur" }],
        // company: [{ message: "Please enter the company", trigger: "blur" }],
        // job: [{ message: "Please enter the job title", trigger: "blur" }],
        // content: [{ message: "Please enter the content", trigger: "blur" }],
        // category: [
        //   {
        //     validator: categoryValid,
        //     trigger: "change",
        //   },
        // ],
      },
      uploadList: [],
      defaultList: [],
      imgName: "",
      flagStyle: "",
      visible: false,
      countryList: [],
      provinceArr: [],
      citiesArr: [],
    };
  },
  computed: {
    ...mapGetters({
      uploadUrl: "getUploadUrl",
      formHeaders: "getFormHeaders",
      userInfo: "getUser",
      exhibitionId: "getExhibitionId",
      token: "getToken",
      country: "getCountry",
    }),
  },
  created () {
    this.areaList = provincial.data;
    this.getUser();
  },

  methods: {
    ...mapMutations({
      setUser: "setUser",
    }),
    ...mapActions({
      getCountry: "toolModule/getCountry",
      getCountrySub: "toolModule/getCountrySub",
      getCategory: "getCategory",
      patchUser: "user/patchUser",
      patchUserCategory: "user/patchUserCategory",
      graphqlPost: "graphqlPost",
    }),
    onConfirm (e) {
      this.country.forEach((c) => {
        // 选择国旗
        if (c.nameEn == e) {
          this.flagStyle = c.shortNameEn;
        }
      });
    },
    addCategoryArrr () {
      if (this.formInline.category.length >= 5) {
        this.$Message.warning(`No more than 5`);
        return;
      }
      this.formInline.category.push([]);
      this.cateKey += 1;
    },
    delCategoryArrr (i) {
      this.cateKey += 1;
      this.formInline.category.splice(i, 1);
      this.$nextTick(() => {
        this.formInline.category = JSON.parse(JSON.stringify(this.formInline.category));
      });
    },
    changeCategoryArr () {
      setTimeout((c) => {
        this.$refs["formInline"].validateField("category");
      }, 400);
    },
    async getUser () {
      let query = `#graphql
        query{
          memberQuery{
            current{
              address
              avatarUrl
              balance
              birthday
              businessCard
              city
              company 
              country
              county
              createAt
              deleteAt
              department
              email
              emailConfirmed
              followCount
              id
              inMember(exhibitionId:"${this.exhibitionId}"){ 
                business
                category
                id 
                memberId
                originId
                isBlacklist
                originLang
                originRemark
                originType
                regAt
                sayCount
                sayUserCount
                userId
                userRole
                exhibitorId
                companyUsers{
                  id
                  isAppointment
                  company {
                    nameEn
                  }
                }
              }
              introduction
              isDeleted
              jobTitle
              jobTitleEn
              name
              nameEn
              nickName
              phone
              phoneConfirm
              province
              talkCount
              talkSuccessedCount
              title
              updateAt
              userId
            }
          } 
        }
      `;
      let opt = {
        opt: {
          query: query,
        },
        token: this.token,
      };
      let data = await this.graphqlPost(opt);
      console.log(data, "this.userInfo...");
      if (data.data && data.data.memberQuery && data.data.memberQuery.current) {
        this.setUser(data.data.memberQuery.current);
        this.init();
      }
    },
    async init () {
      this.initCate();
      let city = [];
      if (this.userInfo.province) {
        city.push(this.userInfo.province);
        if (this.userInfo.city) {
          city.push(this.userInfo.city);
        }
        if (this.userInfo.county) {
          city.push(this.userInfo.county);
        }
      }
      this.formInline = {
        nameEn: this.userInfo.nameEn,
        phone: this.userInfo.phone, // 手机
        company: this.userInfo.company, // 公司
        job: this.userInfo.jobTitle,
        content: this.userInfo.introduction,
        email: this.userInfo.email,
        region: this.userInfo.country,
        city: city,
      };
      if (this.userInfo.avatarUrl) {
        this.defaultList = [
          {
            name: this.userInfo.nameEn,
            url: this.userInfo.avatarUrl,
            status: "finished",
            percentage: 100,
          },
        ];
        this.uploadList = this.defaultList;
        this.formInline.avatarUrl = this.uploadList[0].url;
      }
    },
    async initCate () {
      let res = await this.getCategory();
      if (res && res.categories) {
        let ProCategory = JSON.parse(JSON.stringify(res.categories));
        ProCategory.map((c) => {
          c.value = c.nameEn || c.name;
          c.label = c.nameEn || c.name;
          if (c.childrens && c.childrens.length > 0) {
            c.children = c.childrens;
            c.children.map((v) => {
              v.value = v.nameEn || v.name;
              v.label = v.nameEn || v.name;
              if (v.childrens && v.ichildrens.length > 0) {
                v.children = v.childrens;
                v.children.map((z) => {
                  z.value = z.nameEn || z.name;
                  z.label = z.nameEn || z.name;
                });
              }
            });
          }
        });
        console.log('categoryList', ProCategory);
        this.categoryList = ProCategory;
        let typeList = [];
        this.formInline.category = [];
        let array = [];
        if (this.userInfo && this.userInfo.inMember && this.userInfo.inMember.category) {
          typeList = this.userInfo.inMember.category.split(";");
          typeList.forEach((c) => {
            if (c.indexOf(">")) {
              c = c.split(">");
            }
            array.push(c);
          });
        } else {
          typeList = this.userInfo.inMember.category ? this.userInfo.inMember.category.split(";") : [];
          array.push(typeList);
        }
        this.formInline.category = array;
      }
    },
    handleReset (name) {
      this.$refs[name].resetFields();
    },
    async handleSubmit (name) {
      let valid = await this.validForm(name);
      if (!valid) return;
      let req = {
        avatarUrl: this.formInline.avatarUrl,
        company: this.formInline.company,
        introduction: this.formInline.content,
        province: this.formInline.city[0] || "",
        city: this.formInline.city[1] || "",
        county: this.formInline.city[2] || "",
        jobTitle: this.formInline.job,
        phone: this.formInline.phone,
        nameEn: this.formInline.nameEn,
        country: this.formInline.region,
        id: this.userInfo.inMember.memberId,
        flagStyle: this.flagStyle,
      };
      let data = await this.patchUser(req);
      if (data.result) {
        this.getUser();
        let opt = {};
        let category = [];
        if (this.formInline.category.length > 0) {
          this.formInline.category.forEach((c) => {
            if (c.length > 1) {
              c = c.join(">");
            } else {
              c = c[0];
            }
            category.push(c);
          });
          (this.formInline.category = category.join(";")),
            (opt = {
              body: {
                category: this.formInline.category,
              },
              id: this.userInfo.inMember.id,
            });
        }
        let data1 = await this.patchUserCategory(opt);
        if (data1.result && data.result) {
          this.$Message.success("Saved successfully");
          this.getUser();
        } else {
          this.$Message.warning("The modification failed");
        }
      } else {
        this.$Message.warning("The modification failed");
      }
    },
    validForm (name) {
      // 验证表单
      return new Promise((resolve, reject) => {
        this.$refs[name].validate((valid) => {
          resolve(valid);
        });
      });
    },
    // 查看上传图片
    handleView (name) {
      this.imgName = name;
      this.visible = true;
    },
    // 移除上传图片
    handleRemove (file) {
      const fileList = this.uploadList;
      this.uploadList.splice(fileList.indexOf(file), 1);
      his.formInline.avatarUrl = "";
    },
    // 上传成功
    handleSuccess (res, file, fileList) {
      if (res && res.data.length) {
        file.url = res.data[0];
        this.uploadList = this.$refs.upload.fileList;
        //this.$refs.formInline.validateField("logo");
        this.formInline.avatarUrl = file.url;
      }
    },
    // 文件格式错误
    handleFormatError (file) {
      this.$Message.warning(`文件格式是不正确的, 请上传jpg或者png格式的图片`);
    },
    // 文件过大
    handleMaxSize (file) {
      this.$Message.warning(`文件大小超过限制, 请上传小于2M的图片`);
    },
    // 上传数量
    handleBeforeUpload () {
      const limitNum = 1;
      const check = this.uploadList.length < limitNum;
      if (!check) {
        this.$Message.warning(`最多上传${limitNum}张图片`);
      }
      return check;
    },
    async changeCountry (val) {
      let item = null;
      this.countryList.forEach((c) => {
        if (c.name === val) item = c;
      });
      if (!item) return;
      this.provinceArr = [];
      this.citiesArr = [];
      this.formInline.province = "";
      this.formInline.city = "";
      let opt = {
        where: {
          equal: {
            n: "parentId",
            v: item.id,
          },
        },
        order: [
          {
            n: "showIndex",
            v: 1,
          },
        ],
      };
      let provinceList = await this.getCountrySub(opt);
      if (provinceList && provinceList.length > 0) {
        this.provinceArr = provinceList;
      }
    },
    changeProvince (val) { },
    async changeProvinceValue (item) {
      let opt = {
        where: {
          equal: {
            n: "parentId",
            v: item.id,
          },
        },
        order: [
          {
            n: "showIndex",
            v: 1,
          },
        ],
      };
      let cityList = await this.getCountrySub(opt);
      if (cityList && cityList.length > 0) {
        this.citiesArr = cityList;
      } else {
        this.formInline.city = "";
        this.citiesArr = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.content {
  .m-card {
    border: solid 1px #ededed;
    min-height: 600px;

    .mc-top-title::before {
      opacity: 0;
      font-size: 16px;
    }
  }
}

.my-info {
  .tip {
    line-height: 40px;
    display: inline-block;
    padding-left: 14px;
    font-size: 14px;
    @include font_color(#1890ff);
    cursor: pointer;
  }
}
.my-upload-list-cover i {
  margin: 40px 2px;
}
</style>
